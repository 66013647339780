import classNames from 'classnames'
import React, { useState } from 'react'

const Tooltip: React.FC<{ children: React.ReactNode; text: string }> = React.memo(
    ({ children, text }) => {
        const [show, setShow] = useState(false)
        const toggleTooltip = () => setShow(prev => !prev)

        return (
            <div className="relative">
                <div
                    className={classNames(
                        'absolute text-white p-2 rounded-md bg-dark3 h-9 left-8 w-max -bottom-1.5',
                        show ? 'flex items-center justify-center' : 'hidden',
                    )}
                >
                    {text}
                    <div
                        className="absolute -left-3.5 top-2.5 border-8"
                        style={{ borderColor: 'transparent #516686 transparent transparent' }}
                    />
                </div>
                <div className="w-max h-auto cursor-pointer" onClick={toggleTooltip}>
                    {children}
                </div>
            </div>
        )
    },
    () => true,
)

export default Tooltip
