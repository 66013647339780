import React from 'react'

export const Modal: React.FC<React.PropsWithChildren<{ onClose?: () => void }>> = props => {
    return (
        <div className="fixed z-50 inset-0 overflow-y-auto">
            <div className="items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center block sm:p-0">
                <div className="fixed inset-0 transition-opacity animate-fade-in" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>
                <span className="inline-block align-middle h-screen" aria-hidden="true">
                    &#8203;
                </span>
                <div
                    className="relative inline-block bg-white rounded-3xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 align-middle max-w-lg w-full animate-fade-in"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline"
                >
                    {props.onClose && (
                        <div className="absolute top-0 right-0 pt-4 pr-4 flex">
                            <button
                                className="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-white"
                                onClick={props.onClose}
                            >
                                <svg
                                    className="h-6 w-6"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                        </div>
                    )}

                    <div className="bg-white p-6">{props.children}</div>
                </div>
            </div>
        </div>
    )
}
