import classNames from 'classnames'
import React, { CSSProperties } from 'react'
import { CheckIcon } from './CheckIcon'

export interface ChoiceOptionProps {
    checked?: boolean
    label: string
    checkbox?: boolean
    className?: string
    style?: CSSProperties
    labelClassName?: string
    onClick?: () => void
}

export const ChoiceOption: React.FC<ChoiceOptionProps> = ({
    checked = false,
    label,
    checkbox,
    className,
    labelClassName,
    ...props
}) => {
    const wrapperStyles = classNames(
        'bg-boost6 hover:bg-boost6-hover text-dark1 flex items-center rounded-xl p-4 font-semibold text-sm xs:text-base 3xl:text-lg cursor-pointer duration-300 ease-in-out',
        className,
    )

    const checkboxStyles = classNames(
        'w-6 h-6 mr-4  flex justify-center items-center text-white font-bold',
        checked ? 'bg-boost2' : 'bg-white border-2 border-checkboxborder',
        checkbox ? 'rounded-lg' : 'rounded-full',
    )

    return (
        <div className={wrapperStyles} {...props}>
            <div className={checkboxStyles}>{checked && <CheckIcon className="w-5 h-5" />}</div>
            <div className={labelClassName}>{label}</div>
        </div>
    )
}
