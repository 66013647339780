import React from 'react'
import { useTranslation } from '../i18n'
import { ChoiceOption } from './ChoiceOption'

export interface ChoicesProps {
    name?: string
    options: string[]
    selected?: string[]
    multiple?: boolean
    labelClassName?: string
    onClick?: (option: string) => void
    onChange?: (values: string[]) => void
    width?: string
    labelPrefix?: string
}

/**
 * Radio Button
 */
export const Choices: React.FC<ChoicesProps> = ({
    name,
    options,
    selected = [],
    multiple = false,
    labelClassName,
    onChange,
    onClick,
    width,
    labelPrefix = '',
    ...props
}) => {
    const handleOptionChange = (value: string) => {
        const index = selected.indexOf(value)
        let next: string[]
        if (index >= 0) {
            next = selected.filter(v => v !== value)
        } else {
            next = multiple ? [...selected, value] : [value]
        }
        onChange(next)
    }

    const { t, i18n } = useTranslation()

    return (
        <div className={`grid gap-y-2 grid-cols-1 overflow-x-hidden md:overflow-x-visible ${width}`} {...props}>
            {options.map((option, i) => {
                const label = i18n.exists(`survey:${name}.options.${option}`)
                    ? t(`survey:${name}.options.${option}`)
                    : `${labelPrefix}${t(option)}`
                return (
                    <ChoiceOption
                        key={option}
                        label={label}
                        checked={selected.includes(option)}
                        onClick={onClick ? () => onClick(option) : () => handleOptionChange(option)}
                        className="animate-fade-in-left"
                        labelClassName={labelClassName}
                        style={{ animationDelay: `${i * 75}ms` }}
                        checkbox={multiple}
                    />
                )
            })}
        </div>
    )
}
